import React from "react";
import Header from "./header";
import Footer from "../components/common/footer";
import { graphql } from "gatsby";
import PrivacyPolicy from "../components/common/privacypolicydetails";
import { useTranslation, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL

const Privacypolicy = () => {

  const { language } = React.useContext(I18nextContext)
  const buildCanonicalUrl = () =>

    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/privacy-policy/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/privacy-policy/`
  const canoncalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation()

  return (
    <div className="Layout">
      <Header />
      <PrivacyPolicy />
      <Footer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Logipulse Support - Logistics Support & Services")}</title>
        
        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
       
        <meta
          name="description"
          content={t("At Logipulse, our trained personnel offer optimal support and maintenance services for air, land, and ocean freight software, WPS software, 3PL solutions and more. Get hassle-free logistics support services from Logipulse.")}
        />
        <meta
          name="Keywords"
          content={t("logistics support and maintenance services")}
          data-react-helmet="true"
        />
      </Helmet>
    </div>
  )
}

export default Privacypolicy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
