import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/support.scss";
import Photo from "../../components/Photo";
import "../../styles/accodian.scss";
import { useState } from 'react';
import {Trans,I18nextContext,Link} from 'gatsby-plugin-react-i18next';
import { Helmet } from "react-helmet"

function Privacypolicydetails() {
  const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
  const context = React.useContext(I18nextContext);
 
  const [selected, setselected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setselected(null)
    }
    setselected(i)
  }

  return (
    <div> 
        
      <div className="banner-feature no-supportmargin">
        <div className="container container-style">
          <div className="row">
            <div className="col-md-12">
              <div className="overlay-freight"></div>

              <div className="right-img"></div>
            </div>

            <div className="col-md-12">
              <div className="contents-imgs">
                <div className="pricing_details" lang={context.language}>
                  <Link to ="/"><Trans>HOME</Trans></Link> / <span className="view_features"><Link to="/privacy-policy/"><Trans>PRIVACY POLICY</Trans></Link></span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row support_position" lang={context.language}>
          <div class="col-md-6">
            <div class="getfreedemo_contents">
              {/* <!-- multistep form --> */}
              <div className="support_heading_section" >
                <div className="supportprofile" lang={context.language}>
                  {/* <b><Trans>Write to Us</Trans></b> */}
                  <div className="subsub_main supportdetails_main"><Trans>For information on how STELLOSYS handles your personal information, see our Privacy Policy.</Trans></div>
                </div>
              </div>
            </div>      
          </div>
        </div>
      </div>

      <div class="end_divis_support">
        <div className="header_contents_sections text-right"></div>
        <div className="container">
          <div className="row">
            <div class="col-sm-12">
              <Photo
                src="Bottom Image-11.svg"
                class="innerimage"
                alt=""
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacypolicydetails
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
